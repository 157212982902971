/* #suggestion body {
    width: 100% !important;
    background-image: radial-gradient( circle 610px at 5.2% 51.6%,  rgba(5,8,114,1) 0%, rgba(7,3,53,1) 97.5% );
} */
#suggestion {
    padding-top: 10px;
    background-image: url('./img/suggbg.jpg');
    background-size: cover;
}
#suggestion #form-text {  /* //TITLE */
    color: #13C0FE;
    font-size: auto;
    font-weight: bold;
    columns: mb-5; 
}

#suggestion #button {
    color:#49014F;
}

#suggestion #form-sugg {
    background: -webkit-linear-gradient(50deg, #49004E 49%, #023546 48%),
    -webkit-linear-gradient(left, #49004E 48%, #023546 48%)
   , linear-gradient(45deg, #49004E 48%, #023546 48%);
  
   flex-direction: column;
   padding:  2vw 4vw;
   width: 100%;
   max-width: 1000px;
   border-radius: 10px;
   box-shadow: 3px 10px 100px#000000;

}

#suggestion form {
    background: -webkit-linear-gradient(50deg, #49004E 49%, #023546 48%),
    -webkit-linear-gradient(left, #49004E 48%, #023546 48%)
   , linear-gradient(45deg, #49004E 48%, #023546 48%);
    flex-direction: column;
    padding:  2vw 4vw;
    width: 95%;
    margin-left: 10%;
    max-width: 900px;
    border-radius: 10px;
    box-shadow: 3px 10px 100px#000000;
  }

#suggestion .discordd {
    color:white;
    font: normal p-1;

}

#suggestion .descrition {
    color:white;
    font: normal p-1;
}

#suggestion .contact {
    color:white;
    font: normal p-1;
}

#suggestion .serv-select {
    color:white !important;
    font: normal p-1;
}

#suggestion #formulaire-sugg {

     margin-top: 50px;
/*     background: -webkit-linear-gradient(50deg, #49004E 49%, #023546 48%),
       -webkit-linear-gradient(left, #49004E 48%, #023546 48%)
      , linear-gradient(45deg, #49004E 48%, #023546 48%); */
    width: 95%;
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
   
   
}

#show-bnt {
/*     position: relative;
    left: 1%; */
    box-shadow: 3px 10px 100px#e00f0f;
}

#suggestion #selec-serv {
    width: 100%;
    border-radius: 50px 50px;
    font-family:Arial, Helvetica, sans-serif;
    text-align: center;
    font-size: auto;
}

#suggestion #BNT-button {
   background-color: #9e0076;
   border-color: #000000;
   width: 30%;
   border-radius:10px;
   position: relative;
   left: 37%;
}

#suggestion #BNT-button:hover {
    background-color: #8CE2FF;
    border-color: #b200f9;
    color: #000000;
    width: 30%;
 
 }

 #suggestion h3 {
    color: white;
 }

