#contact-title h1 {
  color: #13C0FE;
  display: flexbox;
}

#contact {
  background-image: url('./img/contactbg.jpg');
  background-size: cover;
  padding-right: 40%;
}
.form{
 /*    position: relative;
    top: -120%; */
    left: 50%;
    width: 20%;
  }
  
  .contact-page {
    overflow: hidden;
  /*      position: relative;
       top: 15%; */
  /*     background: -webkit-linear-gradient(50deg, #49004E 49%, #023546 48%),
         -webkit-linear-gradient(left, #49004E 48%, #023546 48%)
        , linear-gradient(45deg, #49004E 48%, #023546 48%); */
      width: 100%;
     
      display: flex;
      align-items: center;
      justify-content: center;
    /*   padding: 20px 600px; */
      
    }
  
  .contact-page input {
    text-align: center;
    padding: 10px;
  }
  
  .contact-page select {
    border-radius: 15px 15px;
  /*   margin-bottom: 15px; */
  }
  
  .contact-page fieldset {
    color: rgb(212, 61, 249);
    font-weight: 800;
  /*   margin-bottom: 20px; */
    border-radius: 5px 5px;
    padding: 1px;
  } 
  
  .contact-page fieldset label {
     display: flexbox;
     width: 100%;
     background-color: none !important;
  /*    border-radius: 15px 15px; */
  }
  
  .contact-page fieldset input, textarea {
    border-radius: 15px 15px;
    width: 100%;
    background-color: #99a3a6;
  }
    
    form {
      background: -webkit-linear-gradient(50deg, #49004E 49%, #023546 48%),
      -webkit-linear-gradient(left, #49004E 48%, #023546 48%)
     , linear-gradient(45deg, #49004E 48%, #023546 48%);
      display: flex;
      flex-direction: column;
      padding:  2vw 4vw;
      width: 90%;
      max-width: 600px;
      border-radius: 10px;
      box-shadow: 3px 10px 100px#000000;
   /*    position: relative;
      left: -7%; */
    }
    
  
    
    form input,textarea {
       border: 0;
       margin: 5px 0;
       padding: 20px;
       outline: none;
       background: #f5f5f5;
       font-size: auto;
    }
    .formsub {
      margin: 10px 10px 10px 100px; 
    }
    #send {
      padding: 15px;
      background: #ff5361;
      color: white;
      font-size: auto;
      border: 0;
      outline: none;
      cursor: pointer;
      width: 50%;
      margin-left: 20%;
      /* margin: 10px 10px 10px 100px; */
      border-radius: 30px;
    }
  
    
  
   .contact-page     h1, p {
      color: #ff5361;
    }
    
    .contact-page label {
      border: 0;
     /*  margin: 10px 0; */
      padding: 10px;
      outline: none;
    /*   background: #f5f5f5; */
      font-size: auto;
      text-align: center;
    
    }
    
    .contact-page_need {
      font-size: auto;
    }
    
    .contact-page select {
      padding: 1px;
    }
  
    .error {
      color: red;
      font-size: auto;
      font-weight: bolder;
    }
  