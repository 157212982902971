#about-container {
    position: relative;
    top: 150px;
   background: -webkit-linear-gradient(50deg, #8CE2FF 49%, #023546 48%),
      -webkit-linear-gradient(left, #8CE2FF 48%, #023546 48%)
     , linear-gradient(45deg, #8CE2FF 48%, #023546 48%);
   width: 100%;
   align-items: center;
   justify-content: center;
   padding: 20px 20px;
   display: flex;
   height: 2600px;
   display: flexbox;

 }

 #about-container input {
    text-align: center;
    padding: 10px;
  }
  
  #about-containerselect {
    border-radius: 15px 15px;
    margin-bottom: 15px;
  }
  #about-container fieldset {
    color: rgb(212, 61, 249);
    font-weight: 800;
    margin-bottom: 20px;
    border-radius: 15px 15px;
    padding: 1px;
  } 
  
  #about-container fieldset label {
     display: flexbox;
     width: 100%;
     background-color: none !important;
     border-radius: 15px 15px;
  }
  
  #about-container fieldset input, textarea {
    border-radius: 15px 15px;
    width: 100%;
  }


  /*1ER CONTAINER ///////////////////////////////////////////////////////////////*/
  #inside-about-title {
    margin-top: 50px;
    background: -webkit-linear-gradient(120deg, #49004E 49%, #023546 48%),
    -webkit-linear-gradient(left, #49004E 48%, #023546 48%)
   , linear-gradient(45deg, #49004E 48%, #023546 48%);
   display: flex;
   flex-direction: column;
   padding:  50px;
   width: 100%;
   max-width: 1200px;
   border-radius: 10px;
   box-shadow: 3px 10px 100px#000000;
/*    position: relative;
   height: 100%;
   top: 0px; */
   text-align: left;
 }


    
     
     #inside-about-title h1 {
      color: #14C0FE;
     }
    
     #inside-about-title h2 {
      color: #8CE2FF;
     }
    
     #inside-about-title p {
      color:  #ef12ff;
    /*   font-size: large;
      font-weight: 500;
      font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
      line-height: 38px; */
    }

  #inside-about-title:hover{
    transform: scale(1.1);
   }

  #inside-about-title img {
    width: 75%;
    border-style: solid ;
    border-width: 4px;
    border-color: #14C0FE;
    border-radius: 8px;
   }
    
/*2e CONTAINER  ///////////////////////////////////////*/

#inside-about {
    margin-top: 20px;
    background: -webkit-linear-gradient(120deg, #49004E 49%, #023546 48%),
    -webkit-linear-gradient(left, #49004E 48%, #023546 48%)
   , linear-gradient(45deg, #49004E 48%, #023546 48%);
   flex-direction: column;
   padding: 50px;
   width: 100%;
   max-width: 600px;
   border-radius: 10px;
   box-shadow: 3px 10px 100px#000000;
 }

 #inside-about h1 {
    color: #14C0FE;
   }

   #inside-about p {
    color:  #ef12ff;
    font-size: large;
    font-weight: 500;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    line-height: 38px;
   }
  
   #inside-about:hover{
    transform: scale(1.1);
   }
  
   #inside-about img {
    width: 75%;
    border-style: solid ;
    border-width: 4px;
    border-color: #14C0FE;
    border-radius: 8px;
   }


   /*3 CONTAINER ////////////////////////////////////////////////////////////////*/

   #inside-about-2 {
    margin-top: 40px;
    background: -webkit-linear-gradient(120deg, #49004E 49%, #023546 48%),
    -webkit-linear-gradient(left, #49004E 48%, #023546 48%)
   , linear-gradient(45deg, #49004E 48%, #023546 48%);
   flex-direction: column;
   padding: 50px;
   width: 100%;
   max-width: 600px;
   border-radius: 10px;
   box-shadow: 3px 10px 100px#000000;
 }

 #inside-about-2 h1 {
  color: #14C0FE;
 }

 #inside-about-2  p {
  color:  #ef12ff;
  font-size: large;
  font-weight: 500;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  line-height: 38px;
 }

 #inside-about-2:hover{
  transform: scale(1.1);
 }

 #inside-about-2  img {
  width: 75%;
  border-style: solid ;
  border-width: 4px;
  border-color: #14C0FE;
  border-radius: 8px;
 }


 /*3 CONTAINER ///////////////////////////////////////////////////*/


 #inside-about-3 {
    margin-top: 40px;
    background: -webkit-linear-gradient(120deg, #49004E 49%, #023546 48%),
    -webkit-linear-gradient(left, #49004E 48%, #023546 48%)
   , linear-gradient(45deg, #49004E 48%, #023546 48%);
   flex-direction:row;
   padding: 50px;
   width: 100%;
   max-width: 600px;
   border-radius: 10px;
   box-shadow: 3px 10px 100px#000000;
  }
  
  #inside-about-3 h1 {
  color: #14C0FE;
  }
  
  #inside-about-3  p {
  color:  #ef12ff;
  font-size: large;
  font-weight: 500;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  line-height: 38px;
  }
  
  #inside-about-3:hover{
  transform: scale(1.1);
  }
  
  #inside-about-3  img {
  width: 100%;
  border-style: solid ;
  border-width: 4px;
  border-color: #14C0FE;
  border-radius: 8px;
  }


  /* 4 CONTAINER /////////////////////////////////////////////////////////////////*/

  #inside-about-4 {
    margin-top: 20px;
    background: -webkit-linear-gradient(120deg, #49004E 49%, #023546 48%),
    -webkit-linear-gradient(left, #49004E 48%, #023546 48%)
   , linear-gradient(45deg, #49004E 48%, #023546 48%);
   flex-direction:row;
   padding: 50px;
   width: 100%;
   max-width: 600px;
   border-radius: 10px;
   box-shadow: 3px 10px 100px#000000;
   height: 100%;
  }
  
  #inside-about-4 h1 {
  color: #14C0FE;
  }
  
  #inside-about-4  p {
  color:  #ef12ff;
  font-size: large;
  font-weight: 500;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  line-height: 38px;
  }
  
  #inside-about-4:hover{
  transform: scale(1.1);
  }
  
  #inside-about-4  img {
  width: 100%;
  border-style: solid ;
  border-width: 4px;
  border-color: #14C0FE;
  border-radius: 8px;
  }
  

  /*5 CONTAINER ///////////////////////////////////////////////////////////////////////////*/


  #inside-about-5 {
    margin-top: 40px;
    background: -webkit-linear-gradient(120deg, #49004E 49%, #023546 48%),
    -webkit-linear-gradient(left, #49004E 48%, #023546 48%)
   , linear-gradient(45deg, #49004E 48%, #023546 48%);
   flex-direction:row;
   padding: 50px;
   width: 100%;
   max-width: 600px;
   border-radius: 10px;
   box-shadow: 3px 10px 100px#000000;
  }
  
  #inside-about-5 h1 {
  color: #14C0FE;
  }
  
  #inside-about-5  p {
  color:  #ef12ff;
  font-size: large;
  font-weight: 500;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  line-height: 38px;
  }
  
  #inside-about-5:hover{
  transform: scale(1.1);
  }
  
  #inside-about-5  img {
  width: 100%;
  border-style: solid ;
  border-width: 4px;
  border-color: #14C0FE;
  border-radius: 8px;
  }

  /* 6 CONTAINER /////////////////////////////////////////////////////////////////*/

  #inside-about-6 {
    margin-top: 40px;
    background: -webkit-linear-gradient(120deg, #49004E 49%, #023546 48%),
    -webkit-linear-gradient(left, #49004E 48%, #023546 48%)
   , linear-gradient(45deg, #49004E 48%, #023546 48%);
   flex-direction:row;
   padding: 50px;
   width: 100%;
   max-width: 600px;
   border-radius: 10px;
   box-shadow: 3px 10px 100px#000000;
  }
  
  #inside-about-6 h1 {
  color: #14C0FE;
  }
  
  #inside-about-6 p {
  color:  #ef12ff;
  font-size: large;
  font-weight: 500;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  line-height: 38px;
  }
  
  #inside-about-6:hover{
  transform: scale(1.1);
  }
  
  #inside-about-6  img {
  width: 100%;
  border-style: solid ;
  border-width: 4px;
  border-color: #14C0FE;
  border-radius: 8px;
  }

  /* 7 CONTAINER /////////////////////////////////////////////////////*/

  #inside-about-7 {
    margin-top: 20px;
    background: -webkit-linear-gradient(120deg, #49004E 49%, #023546 48%),
    -webkit-linear-gradient(left, #49004E 48%, #023546 48%)
   , linear-gradient(45deg, #49004E 48%, #023546 48%);
   flex-direction:row;
   padding: 50px;
   width: 100%;
   max-width: 600px;
   border-radius: 10px;
   box-shadow: 3px 10px 100px#000000;
  }
  
  #inside-about-7 h1 {
  color: #14C0FE;
  }
  
  #inside-about-7  p {
  color:  #ef12ff;
  font-size: large;
  font-weight: 500;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  line-height: 38px;
  }
  
  #inside-about-7:hover{
  transform: scale(1.1);
  }
  
  #inside-about-7  img {
  width: 100%;
  border-style: solid ;
  border-width: 4px;
  border-color: #14C0FE;
  border-radius: 8px;
  }
  

  /* 8 CONTAINER ////////////////////////////////*/

  #inside-about-8 {
    margin-top: 20px;
    background: -webkit-linear-gradient(120deg, #49004E 49%, #023546 48%),
    -webkit-linear-gradient(left, #49004E 48%, #023546 48%)
   , linear-gradient(45deg, #49004E 48%, #023546 48%);
   flex-direction:row;
   padding: 50px;
   width: 100%;
   max-width: 600px;
   border-radius: 10px;
   box-shadow: 3px 10px 100px#000000;
  }
  
  #inside-about-8 h1 {
  color: #14C0FE;
  }
  
  #inside-about-8  p {
  color:  #ef12ff;
  font-size: large;
  font-weight: 500;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  line-height: 38px;
  }
  
  #inside-about-8:hover{
  transform: scale(1.1);
  }
  
  #inside-about-8  img {
  width: 100%;
  border-style: solid ;
  border-width: 4px;
  border-color: #14C0FE;
  border-radius: 8px;
  }