@import url("https://fonts.googleapis.com/css?family=Open+Sans|PT+Sans+Narrow");
 body {
  overflow: hidden;
  margin-top: -20px;
  font-family: "Open Sans", sans-serif;
}

#errorPage .not-found {
  position: relative;
  overflow: hidden;
  margin: 0 -20vw;
  height: 110vh;
}
#errorPage .not-found [class*=wave] {
  position: absolute;
}
#errorPage .not-found div {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
}
#errorPage .not-found div.wave-1 {
  background: url(http://res.cloudinary.com/andrewhani/image/upload/v1524501869/404/wave-1.svg);
}
#errorPage .not-found div.wave-2 {
  background: url(http://res.cloudinary.com/andrewhani/image/upload/v1524501869/404/wave-2.svg);
}
#errorPage .not-found div.wave-3 {
  background: url(http://res.cloudinary.com/andrewhani/image/upload/v1524501869/404/wave-3.svg);
}
#errorPage .not-found div.wave-4 {
  background: url(http://res.cloudinary.com/andrewhani/image/upload/v1524501869/404/wave-4.svg);
}
#errorPage .not-found div.wave-5 {
  background: url(http://res.cloudinary.com/andrewhani/image/upload/v1524501869/404/wave-5.svg);
}
#errorPage .not-found div.wave-6 {
  background: url(http://res.cloudinary.com/andrewhani/image/upload/v1524501869/404/wave-6.svg);
}
#errorPage .not-found div.wave-7 {
  background: url(http://res.cloudinary.com/andrewhani/image/upload/v1524501869/404/wave-7.svg);
}
#errorPage .not-found div.sky-bg {
  background: url(http://res.cloudinary.com/andrewhani/image/upload/v1524821915/404/bg-1_gvybzk.svg);
  height: 100%;
}
#errorPage .not-found div[class*=wave]:not(.wave-4) {
  height: calc(100% - 250px);
}
#errorPage .not-found div.wave-4 {
  height: calc(100% - 430px);
}
#errorPage .not-found .boat {
  position: absolute;
  top: 0;
  right: 15%;
  width: 150px;
  -webkit-animation: boat 15s cubic-bezier(0.65, 0.05, 0.36, 1) infinite;
  -moz-animation: boat 15s cubic-bezier(0.65, 0.05, 0.36, 1) infinite;
  -o-animation: boat 15s cubic-bezier(0.65, 0.05, 0.36, 1) infinite;
  animation: boat 15s cubic-bezier(0.65, 0.05, 0.36, 1) infinite;
}
#errorPage .not-found .wave-lost {
  position: absolute;
  top: 20%;
  left: 50%;
  color: #fff;
  font-size: 20rem;
  -webkit-animation: surf 2s;
  -moz-animation: surf 2s;
  -o-animation: surf 2s;
  animation: surf 2s;
}
#errorPage .not-found .wave-lost span {
  float: left;
  -webkit-animation: float 3s ease-in infinite;
  -moz-animation: float 3s ease-in infinite;
  -o-animation: float 3s ease-in infinite;
  animation: float 3s ease-in infinite;
}
#errorPage .not-found .wave-lost span:nth-child(2) {
  -webkit-animation-delay: 2.5s;
  -moz-animation-delay: 2.5s;
  -o-animation-delay: 2.5s;
  animation-delay: 2.5s;
}
#errorPage .not-found .wave-lost span:nth-child(3) {
  -webkit-animation-delay: 4.5s;
  -moz-animation-delay: 4.5s;
  -o-animation-delay: 4.5s;
  animation-delay: 4.5s;
}
#errorPage .not-found .wave-island {
  position: absolute;
  top: 130px;
  left: 20%;
  padding: 10px;
  width: 170px;
}
#errorPage .not-found .wave-message {
  position: absolute;
  bottom: 100px;
  left: 50%;
  padding-right: 50%;
  height: auto !important;
  color: #fff;
  font-size: 3rem;
  text-align: left;
  -webkit-animation: wave-message 1s;
  -moz-animation: wave-message 1s;
  -o-animation: wave-message 1s;
  animation: wave-message 1s;
}

@-webkit-keyframes boat {
  0% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: rotate(-15deg) translate3d(400px, 0px, 0px);
    -moz-transform: rotate(-15deg) translate3d(400px, 0px, 0px);
    -ms-transform: rotate(-15deg) translate3d(400px, 0px, 0px);
    -o-transform: rotate(-15deg) translate3d(400px, 0px, 0px);
    transform: rotate(-15deg) translate3d(400px, 0px, 0px);
  }
  20% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: rotate(15deg) translate3d(-20vw, 0, 0px);
    -moz-transform: rotate(15deg) translate3d(-20vw, 0, 0px);
    -ms-transform: rotate(15deg) translate3d(-20vw, 0, 0px);
    -o-transform: rotate(15deg) translate3d(-20vw, 0, 0px);
    transform: rotate(15deg) translate3d(-20vw, 0, 0px);
  }
  25% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: rotate(-7deg) translate3d(-25vw, 0, 0px);
    -moz-transform: rotate(-7deg) translate3d(-25vw, 0, 0px);
    -ms-transform: rotate(-7deg) translate3d(-25vw, 0, 0px);
    -o-transform: rotate(-7deg) translate3d(-25vw, 0, 0px);
    transform: rotate(-7deg) translate3d(-25vw, 0, 0px);
  }
  50% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: rotate(5deg) translate3d(-50vw, 0, 0px);
    -moz-transform: rotate(5deg) translate3d(-50vw, 0, 0px);
    -ms-transform: rotate(5deg) translate3d(-50vw, 0, 0px);
    -o-transform: rotate(5deg) translate3d(-50vw, 0, 0px);
    transform: rotate(5deg) translate3d(-50vw, 0, 0px);
  }
  60% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: rotate(-1deg) translate3d(-60vw, 0, 0px);
    -moz-transform: rotate(-1deg) translate3d(-60vw, 0, 0px);
    -ms-transform: rotate(-1deg) translate3d(-60vw, 0, 0px);
    -o-transform: rotate(-1deg) translate3d(-60vw, 0, 0px);
    transform: rotate(-1deg) translate3d(-60vw, 0, 0px);
  }
  100% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: rotate(2deg) translate3d(-100vw, 0, 0px);
    -moz-transform: rotate(2deg) translate3d(-100vw, 0, 0px);
    -ms-transform: rotate(2deg) translate3d(-100vw, 0, 0px);
    -o-transform: rotate(2deg) translate3d(-100vw, 0, 0px);
    transform: rotate(2deg) translate3d(-100vw, 0, 0px);
  }
}
@-moz-keyframes boat {
  0% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: rotate(-15deg) translate3d(400px, 0px, 0px);
    -moz-transform: rotate(-15deg) translate3d(400px, 0px, 0px);
    -ms-transform: rotate(-15deg) translate3d(400px, 0px, 0px);
    -o-transform: rotate(-15deg) translate3d(400px, 0px, 0px);
    transform: rotate(-15deg) translate3d(400px, 0px, 0px);
  }
  20% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: rotate(15deg) translate3d(-20vw, 0, 0px);
    -moz-transform: rotate(15deg) translate3d(-20vw, 0, 0px);
    -ms-transform: rotate(15deg) translate3d(-20vw, 0, 0px);
    -o-transform: rotate(15deg) translate3d(-20vw, 0, 0px);
    transform: rotate(15deg) translate3d(-20vw, 0, 0px);
  }
  25% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: rotate(-7deg) translate3d(-25vw, 0, 0px);
    -moz-transform: rotate(-7deg) translate3d(-25vw, 0, 0px);
    -ms-transform: rotate(-7deg) translate3d(-25vw, 0, 0px);
    -o-transform: rotate(-7deg) translate3d(-25vw, 0, 0px);
    transform: rotate(-7deg) translate3d(-25vw, 0, 0px);
  }
  50% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: rotate(5deg) translate3d(-50vw, 0, 0px);
    -moz-transform: rotate(5deg) translate3d(-50vw, 0, 0px);
    -ms-transform: rotate(5deg) translate3d(-50vw, 0, 0px);
    -o-transform: rotate(5deg) translate3d(-50vw, 0, 0px);
    transform: rotate(5deg) translate3d(-50vw, 0, 0px);
  }
  60% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: rotate(-1deg) translate3d(-60vw, 0, 0px);
    -moz-transform: rotate(-1deg) translate3d(-60vw, 0, 0px);
    -ms-transform: rotate(-1deg) translate3d(-60vw, 0, 0px);
    -o-transform: rotate(-1deg) translate3d(-60vw, 0, 0px);
    transform: rotate(-1deg) translate3d(-60vw, 0, 0px);
  }
  100% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: rotate(2deg) translate3d(-100vw, 0, 0px);
    -moz-transform: rotate(2deg) translate3d(-100vw, 0, 0px);
    -ms-transform: rotate(2deg) translate3d(-100vw, 0, 0px);
    -o-transform: rotate(2deg) translate3d(-100vw, 0, 0px);
    transform: rotate(2deg) translate3d(-100vw, 0, 0px);
  }
}
@-o-keyframes boat {
  0% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: rotate(-15deg) translate3d(400px, 0px, 0px);
    -moz-transform: rotate(-15deg) translate3d(400px, 0px, 0px);
    -ms-transform: rotate(-15deg) translate3d(400px, 0px, 0px);
    -o-transform: rotate(-15deg) translate3d(400px, 0px, 0px);
    transform: rotate(-15deg) translate3d(400px, 0px, 0px);
  }
  20% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: rotate(15deg) translate3d(-20vw, 0, 0px);
    -moz-transform: rotate(15deg) translate3d(-20vw, 0, 0px);
    -ms-transform: rotate(15deg) translate3d(-20vw, 0, 0px);
    -o-transform: rotate(15deg) translate3d(-20vw, 0, 0px);
    transform: rotate(15deg) translate3d(-20vw, 0, 0px);
  }
  25% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: rotate(-7deg) translate3d(-25vw, 0, 0px);
    -moz-transform: rotate(-7deg) translate3d(-25vw, 0, 0px);
    -ms-transform: rotate(-7deg) translate3d(-25vw, 0, 0px);
    -o-transform: rotate(-7deg) translate3d(-25vw, 0, 0px);
    transform: rotate(-7deg) translate3d(-25vw, 0, 0px);
  }
  50% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: rotate(5deg) translate3d(-50vw, 0, 0px);
    -moz-transform: rotate(5deg) translate3d(-50vw, 0, 0px);
    -ms-transform: rotate(5deg) translate3d(-50vw, 0, 0px);
    -o-transform: rotate(5deg) translate3d(-50vw, 0, 0px);
    transform: rotate(5deg) translate3d(-50vw, 0, 0px);
  }
  60% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: rotate(-1deg) translate3d(-60vw, 0, 0px);
    -moz-transform: rotate(-1deg) translate3d(-60vw, 0, 0px);
    -ms-transform: rotate(-1deg) translate3d(-60vw, 0, 0px);
    -o-transform: rotate(-1deg) translate3d(-60vw, 0, 0px);
    transform: rotate(-1deg) translate3d(-60vw, 0, 0px);
  }
  100% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: rotate(2deg) translate3d(-100vw, 0, 0px);
    -moz-transform: rotate(2deg) translate3d(-100vw, 0, 0px);
    -ms-transform: rotate(2deg) translate3d(-100vw, 0, 0px);
    -o-transform: rotate(2deg) translate3d(-100vw, 0, 0px);
    transform: rotate(2deg) translate3d(-100vw, 0, 0px);
  }
}
/* Standard syntax */
@keyframes boat {
  0% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: rotate(-15deg) translate3d(400px, 0px, 0px);
    -moz-transform: rotate(-15deg) translate3d(400px, 0px, 0px);
    -ms-transform: rotate(-15deg) translate3d(400px, 0px, 0px);
    -o-transform: rotate(-15deg) translate3d(400px, 0px, 0px);
    transform: rotate(-15deg) translate3d(400px, 0px, 0px);
  }
  20% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: rotate(15deg) translate3d(-20vw, 0, 0px);
    -moz-transform: rotate(15deg) translate3d(-20vw, 0, 0px);
    -ms-transform: rotate(15deg) translate3d(-20vw, 0, 0px);
    -o-transform: rotate(15deg) translate3d(-20vw, 0, 0px);
    transform: rotate(15deg) translate3d(-20vw, 0, 0px);
  }
  25% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: rotate(-7deg) translate3d(-25vw, 0, 0px);
    -moz-transform: rotate(-7deg) translate3d(-25vw, 0, 0px);
    -ms-transform: rotate(-7deg) translate3d(-25vw, 0, 0px);
    -o-transform: rotate(-7deg) translate3d(-25vw, 0, 0px);
    transform: rotate(-7deg) translate3d(-25vw, 0, 0px);
  }
  50% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: rotate(5deg) translate3d(-50vw, 0, 0px);
    -moz-transform: rotate(5deg) translate3d(-50vw, 0, 0px);
    -ms-transform: rotate(5deg) translate3d(-50vw, 0, 0px);
    -o-transform: rotate(5deg) translate3d(-50vw, 0, 0px);
    transform: rotate(5deg) translate3d(-50vw, 0, 0px);
  }
  60% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: rotate(-1deg) translate3d(-60vw, 0, 0px);
    -moz-transform: rotate(-1deg) translate3d(-60vw, 0, 0px);
    -ms-transform: rotate(-1deg) translate3d(-60vw, 0, 0px);
    -o-transform: rotate(-1deg) translate3d(-60vw, 0, 0px);
    transform: rotate(-1deg) translate3d(-60vw, 0, 0px);
  }
  100% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: rotate(2deg) translate3d(-100vw, 0, 0px);
    -moz-transform: rotate(2deg) translate3d(-100vw, 0, 0px);
    -ms-transform: rotate(2deg) translate3d(-100vw, 0, 0px);
    -o-transform: rotate(2deg) translate3d(-100vw, 0, 0px);
    transform: rotate(2deg) translate3d(-100vw, 0, 0px);
  }
}
@-webkit-keyframes float {
  0%, 100% {
    -webkit-transform: rotate(3deg) translate3d(0px, -10px, 0px);
    -moz-transform: rotate(3deg) translate3d(0px, -10px, 0px);
    -ms-transform: rotate(3deg) translate3d(0px, -10px, 0px);
    -o-transform: rotate(3deg) translate3d(0px, -10px, 0px);
    transform: rotate(3deg) translate3d(0px, -10px, 0px);
  }
  50% {
    -webkit-transform: rotate(-3deg) translate3d(0px, 10px, 0px);
    -moz-transform: rotate(-3deg) translate3d(0px, 10px, 0px);
    -ms-transform: rotate(-3deg) translate3d(0px, 10px, 0px);
    -o-transform: rotate(-3deg) translate3d(0px, 10px, 0px);
    transform: rotate(-3deg) translate3d(0px, 10px, 0px);
  }
}
@-moz-keyframes float {
  0%, 100% {
    -webkit-transform: rotate(3deg) translate3d(0px, -10px, 0px);
    -moz-transform: rotate(3deg) translate3d(0px, -10px, 0px);
    -ms-transform: rotate(3deg) translate3d(0px, -10px, 0px);
    -o-transform: rotate(3deg) translate3d(0px, -10px, 0px);
    transform: rotate(3deg) translate3d(0px, -10px, 0px);
  }
  50% {
    -webkit-transform: rotate(-3deg) translate3d(0px, 10px, 0px);
    -moz-transform: rotate(-3deg) translate3d(0px, 10px, 0px);
    -ms-transform: rotate(-3deg) translate3d(0px, 10px, 0px);
    -o-transform: rotate(-3deg) translate3d(0px, 10px, 0px);
    transform: rotate(-3deg) translate3d(0px, 10px, 0px);
  }
}
@-o-keyframes float {
  0%, 100% {
    -webkit-transform: rotate(3deg) translate3d(0px, -10px, 0px);
    -moz-transform: rotate(3deg) translate3d(0px, -10px, 0px);
    -ms-transform: rotate(3deg) translate3d(0px, -10px, 0px);
    -o-transform: rotate(3deg) translate3d(0px, -10px, 0px);
    transform: rotate(3deg) translate3d(0px, -10px, 0px);
  }
  50% {
    -webkit-transform: rotate(-3deg) translate3d(0px, 10px, 0px);
    -moz-transform: rotate(-3deg) translate3d(0px, 10px, 0px);
    -ms-transform: rotate(-3deg) translate3d(0px, 10px, 0px);
    -o-transform: rotate(-3deg) translate3d(0px, 10px, 0px);
    transform: rotate(-3deg) translate3d(0px, 10px, 0px);
  }
}
/* Standard syntax */
@keyframes float {
  0%, 100% {
    -webkit-transform: rotate(3deg) translate3d(0px, -10px, 0px);
    -moz-transform: rotate(3deg) translate3d(0px, -10px, 0px);
    -ms-transform: rotate(3deg) translate3d(0px, -10px, 0px);
    -o-transform: rotate(3deg) translate3d(0px, -10px, 0px);
    transform: rotate(3deg) translate3d(0px, -10px, 0px);
  }
  50% {
    -webkit-transform: rotate(-3deg) translate3d(0px, 10px, 0px);
    -moz-transform: rotate(-3deg) translate3d(0px, 10px, 0px);
    -ms-transform: rotate(-3deg) translate3d(0px, 10px, 0px);
    -o-transform: rotate(-3deg) translate3d(0px, 10px, 0px);
    transform: rotate(-3deg) translate3d(0px, 10px, 0px);
  }
}
@-webkit-keyframes surf {
  0% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: rotate(15deg) translate3d(0, 800px, 0);
    -moz-transform: rotate(15deg) translate3d(0, 800px, 0);
    -ms-transform: rotate(15deg) translate3d(0, 800px, 0);
    -o-transform: rotate(15deg) translate3d(0, 800px, 0);
    transform: rotate(15deg) translate3d(0, 800px, 0);
  }
  30% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: rotate(15deg) translate3d(0, 500px, 0);
    -moz-transform: rotate(15deg) translate3d(0, 500px, 0);
    -ms-transform: rotate(15deg) translate3d(0, 500px, 0);
    -o-transform: rotate(15deg) translate3d(0, 500px, 0);
    transform: rotate(15deg) translate3d(0, 500px, 0);
  }
  100% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: rotate(0) translate3d(0, 0, 0px);
    -moz-transform: rotate(0) translate3d(0, 0, 0px);
    -ms-transform: rotate(0) translate3d(0, 0, 0px);
    -o-transform: rotate(0) translate3d(0, 0, 0px);
    transform: rotate(0) translate3d(0, 0, 0px);
  }
}
@-moz-keyframes surf {
  0% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: rotate(15deg) translate3d(0, 800px, 0);
    -moz-transform: rotate(15deg) translate3d(0, 800px, 0);
    -ms-transform: rotate(15deg) translate3d(0, 800px, 0);
    -o-transform: rotate(15deg) translate3d(0, 800px, 0);
    transform: rotate(15deg) translate3d(0, 800px, 0);
  }
  30% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: rotate(15deg) translate3d(0, 500px, 0);
    -moz-transform: rotate(15deg) translate3d(0, 500px, 0);
    -ms-transform: rotate(15deg) translate3d(0, 500px, 0);
    -o-transform: rotate(15deg) translate3d(0, 500px, 0);
    transform: rotate(15deg) translate3d(0, 500px, 0);
  }
  100% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: rotate(0) translate3d(0, 0, 0px);
    -moz-transform: rotate(0) translate3d(0, 0, 0px);
    -ms-transform: rotate(0) translate3d(0, 0, 0px);
    -o-transform: rotate(0) translate3d(0, 0, 0px);
    transform: rotate(0) translate3d(0, 0, 0px);
  }
}
@-o-keyframes surf {
  0% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: rotate(15deg) translate3d(0, 800px, 0);
    -moz-transform: rotate(15deg) translate3d(0, 800px, 0);
    -ms-transform: rotate(15deg) translate3d(0, 800px, 0);
    -o-transform: rotate(15deg) translate3d(0, 800px, 0);
    transform: rotate(15deg) translate3d(0, 800px, 0);
  }
  30% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: rotate(15deg) translate3d(0, 500px, 0);
    -moz-transform: rotate(15deg) translate3d(0, 500px, 0);
    -ms-transform: rotate(15deg) translate3d(0, 500px, 0);
    -o-transform: rotate(15deg) translate3d(0, 500px, 0);
    transform: rotate(15deg) translate3d(0, 500px, 0);
  }
  100% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: rotate(0) translate3d(0, 0, 0px);
    -moz-transform: rotate(0) translate3d(0, 0, 0px);
    -ms-transform: rotate(0) translate3d(0, 0, 0px);
    -o-transform: rotate(0) translate3d(0, 0, 0px);
    transform: rotate(0) translate3d(0, 0, 0px);
  }
}
/* Standard syntax */
@keyframes surf {
  0% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: rotate(15deg) translate3d(0, 800px, 0);
    -moz-transform: rotate(15deg) translate3d(0, 800px, 0);
    -ms-transform: rotate(15deg) translate3d(0, 800px, 0);
    -o-transform: rotate(15deg) translate3d(0, 800px, 0);
    transform: rotate(15deg) translate3d(0, 800px, 0);
  }
  30% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: rotate(15deg) translate3d(0, 500px, 0);
    -moz-transform: rotate(15deg) translate3d(0, 500px, 0);
    -ms-transform: rotate(15deg) translate3d(0, 500px, 0);
    -o-transform: rotate(15deg) translate3d(0, 500px, 0);
    transform: rotate(15deg) translate3d(0, 500px, 0);
  }
  100% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: rotate(0) translate3d(0, 0, 0px);
    -moz-transform: rotate(0) translate3d(0, 0, 0px);
    -ms-transform: rotate(0) translate3d(0, 0, 0px);
    -o-transform: rotate(0) translate3d(0, 0, 0px);
    transform: rotate(0) translate3d(0, 0, 0px);
  }
}
@-webkit-keyframes wave-message {
  0% {
    -webkit-transform: translate3d(0, 120%, 0);
    -moz-transform: translate3d(0, 120%, 0);
    -ms-transform: translate3d(0, 120%, 0);
    -o-transform: translate3d(0, 120%, 0);
    transform: translate3d(0, 120%, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes wave-message {
  0% {
    -webkit-transform: translate3d(0, 120%, 0);
    -moz-transform: translate3d(0, 120%, 0);
    -ms-transform: translate3d(0, 120%, 0);
    -o-transform: translate3d(0, 120%, 0);
    transform: translate3d(0, 120%, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes wave-message {
  0% {
    -webkit-transform: translate3d(0, 120%, 0);
    -moz-transform: translate3d(0, 120%, 0);
    -ms-transform: translate3d(0, 120%, 0);
    -o-transform: translate3d(0, 120%, 0);
    transform: translate3d(0, 120%, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
/* Standard syntax */
@keyframes wave-message {
  0% {
    -webkit-transform: translate3d(0, 120%, 0);
    -moz-transform: translate3d(0, 120%, 0);
    -ms-transform: translate3d(0, 120%, 0);
    -o-transform: translate3d(0, 120%, 0);
    transform: translate3d(0, 120%, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}


/*BUTTON */






#errorPage * {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* body {
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
} */

#errorPage button{
  border: none;
  border-radius: 15px;
  box-shadow: 0 0 8px #17A8DE;
}

#errorPage button:hover{
  border: none;
  padding: 15px;
  border-radius: 15px;
  box-shadow: inset 0 0 8px #49004E;
  } 

 #foot{
  position: relative;
  top: 15px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: none;
  padding: 15px;
  border-radius: 15px;
  box-shadow: 0 0 8px #17A8DE;
  width: 200px;
  height: 65px;
  background-color: #17A8DE;
  animation-duration: 5s;
  font-family:'Courier New', Courier, monospace;
  color: #17A8DE;
}

#errorPage .button-os{
   position: absolute;
    width: 200px;
    height: 65px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: #49004E;;
    border: 2.5px solid #0e172c;
    transition: background-color 2.2s;
    transition-duration: 3s;
    transition: 1.5s;

  }
  
  #errorPage .button-os:hover{
    position: absolute;
    top: 30px;
    background-color: #fec7d7;
    color: white;
  }
  
  #errorPage .button-os a{
    font-size: 20px;
    display: block;
    text-decoration: none;
    color:#17A8DE;;
    transition: 1.5s;
  }
  
  #errorPage .button-os a:hover{
    letter-spacing: 5px;
  }

  #copyright {
    font-family:'Courier New', Courier, monospace;
  }

  
  #logo404 {
    width: 20%;
  }

  #lost {
    font-family: 'Splash', cursive;
  }

  @media only screen and (max-width: 1000px) {
    #lost {
      position: relative;
      left: -40%;
    }
    #logo404 {
      position: relative;
      left: -20%;
      width: 50%;
    }
    #foot {
      position: relative;
      left: -20%;
    }
    #err404 {
      position: relative;
      left: 35%;
      font-size: 150px;
      }
  }


  @media only screen and (max-width: 300px) {
    #lost {
      position: relative;
      left: -40%;
    }
    #logo404 {
      position: relative;
      left: -20%;
      width: 90%;
    }
    #foot {
      position: relative;
      left: -50%;
    }
    #err404 {
    position: relative;
    left: 15%;
    font-size: 150px;
    }
  }